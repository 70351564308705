<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-07-29 15:47:31
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-25 10:24:53
-->
<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/home/logo.png" alt="" />
    </div>
    <ul class="nav">
      <li :class="{ active: $route.path == '/home' }">
        <router-link to="/home">首页</router-link>
      </li>
      <li :class="{ active: $route.path == '/home/intro' }">
        <router-link to="/home/intro">功能介绍</router-link>
      </li>
    </ul>
    <ul v-if="token" class="user login">
      <li>
        <router-link to="/">我的学堂</router-link>
      </li>
      <li class="avatar" @click="toHome">
        <img :src="userInfo.headPic" alt="" />
      </li>
      <li>{{ userInfo.userName }}</li>
      <li @click="logout">退出</li>
    </ul>
    <ul v-else class="user">
      <li @click="toLogin">登录</li>
      <li class="free" @click="toLogin">免费使用</li>
    </ul>
  </div>
</template>
<script>
import { getCookie, setCookie } from '@/utils/cookies'
import * as type from '@/store/action-types'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
export default {
  data() {
    return {
      token: getCookie('myToken'),
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    console.log(this.userInfo)
  },
  methods: {
    ...mapActions([type.LOGOUT]),
    logout() {
      this[type.LOGOUT]()
    },
    toLogin() {
      setCookie('redirectUrl', window.location.href)
      window.location.href = process.env.VUE_APP_LOGIN_CENTER
    },
    toHome() {
      this.$router.push('/')
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .logo {
    width: 40px;
    height: 40px;
    margin-right: 30px;
    // margin-left: 9px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    li {
      font-size: 16px;
      color: #fefeff;
      cursor: pointer;
      margin-right: 35px;
      padding-bottom: 8px;
      a {
        color: #fefeff;
      }
      &.active {
        font-weight: 500;
        border-bottom: 1px solid #fff;
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    // padding-right: 67px;
    margin-left: auto;
    li {
      font-size: 16px;
      color: #ffffff;
      margin-right: 24px;
      cursor: pointer;
      a {
        color: #fefeff;
      }
      &:last-child {
        margin: 0;
      }
    }
    .free {
      width: 90px;
      height: 30px;
      line-height: 30px;
      border-radius: 6px;
      background-color: #fff;
      font-size: 16px;
      text-align: center;
      color: #15b894;
    }
    img {
      width: 60px;
      height: 60px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 19px;
    }
  }
}
</style>
