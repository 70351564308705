<template>
  <div class="home">
    <div class="section section01">
      <div class="bg">
        <div class="container">
          <home-header />
          <div class="content">
            <div class="text" :style="token ? 'padding-top:0' : ''">
              <h3>明师道</h3>
              <div class="slogan" :style="token ? 'margin-bottom:37px' : ''">
                重构知识体系，引领师道新潮流
              </div>
              <h4>美好的体验即将开始</h4>
              <p :style="token ? 'margin-bottom:34px' : ''">
                零门槛打造专属知识店铺，创建品牌，为师资、培训机构提供一体化解决方案
              </p>
              <div v-if="token" class="form">
                <p>创建属于您的企业的独立学堂</p>
                <div class="input">
                  <input v-model="name" type="text" placeholder="您的学堂名称" />
                  <div class="btn" @click="toUse">免费使用</div>
                </div>
              </div>
              <div v-else class="form">
                <div class="input">
                  <input v-model="phone" type="text" placeholder="输入手机号立即体验" />
                  <div class="btn" @click="toUse">免费使用</div>
                </div>
              </div>
            </div>
            <img src="@/assets/home/s1_pic.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section section02">
      <div class="title">我们的优势</div>
      <ul>
        <li>
          <img src="@/assets/home/s2_icon_1.png" alt="" />
          <p>平台免费使用</p>
          <div class="point"></div>
        </li>
        <li>
          <img src="@/assets/home/s2_icon_2.png" alt="" />
          <p>一站式培训管理</p>
          <div class="point"></div>
        </li>
        <li>
          <img src="@/assets/home/s2_icon_3.png" alt="" />
          <p>全方位客服服务</p>
          <div class="point"></div>
        </li>
        <li>
          <img src="@/assets/home/s2_icon_4.png" alt="" />
          <p>多端协助销售</p>
          <div class="point"></div>
        </li>
        <li>
          <img src="@/assets/home/s2_icon_5.png" alt="" />
          <p>智能报表分析</p>
          <div class="point"></div>
        </li>
      </ul>
    </div>
    <div class="section section03">
      <div class="block">
        <img src="@/assets/home/s3_1.png" alt="" />
        <div class="text">
          <div class="name">对接产业需求</div>
          <div class="intro">
            <p>
              对接产业需求就是要求教育的方向与人才的培养要以产业发展为目标，以产业发展中存在的问题和解决产业发展中问题为导向，去设计培养方案和构建课程体系，以产业政策、产业发展前景与趋势、产业供应链管理、产业核心技术研发、产业师业模式创新、产业成长规律等为内容，去培养产业发展需要的各类创新型、复合型、应用型、工匠型职业人才。
            </p>
          </div>
          <div class="btn" @click="toUse">免费使用</div>
        </div>
      </div>
      <div class="block">
        <div class="text">
          <div class="name">重构知识体系</div>
          <div class="intro">
            <p>
              企业大学教育的特点是职业教育。职业教育是在专业教育的基础上，以职业能力提升为目的，以职业素养、职业技术、职业能力的培养为重点，注重培养各类职业人士发现问题、分析问题、解决问题的能力。
            </p>
            <p>
              以人才培养对象的专业知识为基础，按照职业人士不同岗位的理想的、合理的知识结构和体系为目标，按照“缺什么，补什么”的基本原则，以行业背景为应用场景，重新设计、创新、组合职业人才知识体系。
            </p>
          </div>
        </div>
        <img src="@/assets/home/s3_2.png" alt="" />
      </div>
      <div class="block">
        <div class="text">
          <div class="name">实现教育价值</div>
          <div class="intro">
            面对产业发展对职业人才的需求，教育培训机构和教师队伍应当根据国家职业教育人才培养的目标和方向，在课程内容研发、教学模式创新上下功夫，为产业发展培养具有职业素养、创新能力、工匠精神的各类职业人才。
          </div>
          <ul>
            <li>
              <div class="img">
                <div class="icon">
                  <img src="@/assets/home/s3_card_icon1.png" alt="" />
                </div>
              </div>
              <div>
                <p>依据产业需求，研发课程内容</p>
              </div>
            </li>
            <li>
              <div class="img">
                <div class="icon">
                  <img src="@/assets/home/s3_card_icon2.png" alt="" />
                </div>
              </div>
              <div>
                <p>依据职业分类，研发不同职业及层级的</p>
                <p>职业人士应当具备的知识结构与技能要求</p>
              </div>
            </li>
            <li>
              <div class="img">
                <div class="icon">
                  <img src="@/assets/home/s3_card_icon3.png" alt="" />
                </div>
              </div>
              <div>
                <p>根据不同的市场需求，遵循产业</p>
                <p>成长规律，以实现产业腾飞为目的</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="block container">
        <div class="text">
          <div class="intro">
            <p>
              中潮教育是一家专注职业人才培养，为各类企业打造基于教育大数据的学堂式开放共享型职业能力提升平台的互联网教育企业。中潮教育是平台的服务者、运营者、管理者。
            </p>
          </div>
          <div class="btn" @click="toUse">免费使用</div>
        </div>
        <img src="@/assets/home/s3_3.png" alt="" />
      </div>
    </div>
    <div class="section section04">
      <ul class="container">
        <li>
          <div class="title">为教育培训机构创造什么价值</div>
          <div class="info">
            <p>◆ 提供线上教学空间，实现线上线下相结合特色培训</p>
            <p>◆ 提供线上直播、录播工具，节约制课成本</p>
            <p>◆ 免费宣传，打造品牌形象，提升业内影响和知名度</p>
            <p>◆ 精准抓取目标客户，提高招生效果</p>
            <p>◆ 免费引流，降低招生成本</p>
          </div>
        </li>
        <li>
          <div class="title">为师资创造什么价值</div>
          <div class="info">
            <p>◆ 免费的教学空间，师资可自主开设微校、微课堂、直播平台，组织线上教学</p>
            <p>◆ 免费的教学资源，包括课程设计服务、课程制作工具</p>
            <p>◆ 帮助打造线上金课，打造IP和学术网红，实现知识变现</p>
            <p>◆ 打造线下精品课程，针对客户需求，精准研发课程内容</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="section section05">
      <div class="container">
        <div class="title">学堂展示</div>
        <ul>
          <li v-for="item in orgRecmmond" :key="item.organizationId" class="college">
            <div class="text">
              <div class="name">{{ item.organizationName }}</div>
              <div class="des">{{ item.des }}</div>
            </div>
            <div class="logo">
              <img :src="item.logo" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="foot">
        <div class="foot_m">
          <div class="item-msd">
            <div class="font-Comp">
              <img src="@/assets/home/logo_zc.png" />
              <div>
                <p>湖北中潮教育科技集团有限公司</p>
                <p class="des">China Trend Educational Technology Group Co. LTD</p>
              </div>
            </div>
            <div class="items-jhp">
              <img src="@/assets/login/Pic-mhd.png" />
              <div>
                <p class="font-Dec">明商道——创建企业大学，占领商道制高点</p>
                <p class="font-So">全方位一站式内培平台，搭建持续学习能力提升的生态系统</p>
              </div>
            </div>
          </div>
          <div class="item-qt">
            <div class="items-jhs">
              <img src="@/assets/login/Pic-mzd.png" />
              <div>
                <p class="font-Dec">明职道——弘扬职业精神 打造职道竞争力</p>
                <p class="font-So">过千门各行各业的视频课程，助您职场竞争力提升MAX</p>
              </div>
            </div>
            <div class="items-jh">
              <img src="@/assets/login/Pic-msd.png" />
              <div>
                <p class="font-Dec">明师道——重构知识体系 引领师道新潮流</p>
                <p class="font-So">发布您的优秀课程，拥有千万学子，完成传道授业解惑</p>
              </div>
            </div>
          </div>
          <div class="item-gzh">
            <img src="@/assets/login/logo-wm.png" />
            <p>中潮教育服务号</p>
          </div>
          <div class="item-ewm">
            <img src="@/assets/home/mpcode.png" />
            <p>明师道小程序二维码</p>
          </div>
        </div>
        <div class="foot-n">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">
            Copyright ©2015-2021 版权所有湖北中潮教育科技集团有限公司
            鄂ICP备15017598号-1（中潮教育融e学）
          </a>
          <p class="Powerby">Powered by 中潮教育</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addorg } from '@/api/create'
import to from 'await-to'
// import { orgRecmmond } from '@/api/home'
// import to from 'await-to'
import HomeHeader from './components/HomeHeader'
import { getCookie, setCookie } from '@/utils/cookies'
export default {
  metaInfo() {
    return {
      title: this.$route.meta.title + '-明师道_帮助培训机构和师资端进行知识变现-中潮教育',
      meta: [
        {
          name: 'keyWords',
          content: '明商道,知识店铺,课程变现,在线课程发布平台,培训机构课程发布,精品课程发布',
        },
        {
          name: 'description',
          content:
            '融E学·明师道-零门槛打造专属知识店铺，创建品牌，发布课程，为师资、培训机构提供一体化解决方案。',
        },
      ],
    }
  },
  name: 'Home',
  components: { HomeHeader },
  data() {
    return {
      name: '',
      phone: '',
      orgRecmmond: [
        {
          logo: require('@/assets/home/college1.png'),
          organizationName: '创新创业坊',
          organizationId: 1,
          des:
            '创新创业工作坊是创业者的线上经验汲取空间，致力于创新创业经验的萃取与传播，邀请实战老师与专家学者从创新思维、商业模式、产品打造、市场营销、创业管理等方面提供全方位的智力与经验传授，帮助创业者解决创业问题，提升创新创业能力，实现企业从0-1的重要转变。',
        },
        {
          logo: require('@/assets/home/college2.png'),
          organizationName: '大国工匠营',
          organizationId: 2,
          des:
            '大国工匠营是技能人才进行技能沉淀与学习的平台，致力于行业技能的提升。这里汇集了各行业的领域的专业理论与实践知识，帮助学员补充行业知识，提升行业技能。职业知识与技能对于职业人士的发展有着重要价值，也是企业产业发展与企业核心竞争力打造的关键。',
        },
        {
          logo: require('@/assets/home/college3.png'),
          organizationName: '人文思想书院',
          organizationId: 3,
          des:
            '人文思想书院是一个线上人文探索与思想交流空间，着重于人文知识的沉淀与传播。书院汇集了哲学、历史、法律、文学、艺术、健康等人文领域丰富多彩的课程。强调趣味性个性化的的学习提升，强调有深度非传统的思想交流。',
        },
        {
          logo: require('@/assets/home/college4.png'),
          organizationName: '商道研习社',
          organizationId: 4,
          des:
            '商道研习社是企业中高层管理者的线上学习成长空间，聚焦于企业经营管理中的关键能力提升。以分层分类培养的方式，根据人员的职能岗位分别提供战略管理、领导力艺术、营销管理、人力管理、财税管理等课程。帮助企业中高层管理者解决经营管理的实际问题，提升管理绩效，降低企业熵值，实现企业从1-n的关键成长。',
        },
        {
          logo: require('@/assets/home/college5.png'),
          organizationName: '社区书院',
          organizationId: 5,
          des:
            '社区书院以营造“服务村社、教育惠民、快乐学习”为目标，以提高社区居民精神文化教养和整体素质为宗旨，以线上线下相结合的培训方式，为提升社区居民幸福感提供服务。通过社区书院内生活讲座、养生健康、艺术文化、法律常识、心理咨询、休闲运动等系列课程的学习，推动社区学习型组织建设，提升居民综合素养，提升居民生活幸福感。构建“时时能学、处处可学、人人好学”的良好氛围。',
        },
        {
          logo: require('@/assets/home/college7.png'),
          organizationName: '言职云课堂',
          organizationId: 7,
          des:
            '言职云课堂是职业人士的线上学习空间，注重于职业人士职业能力的提升。课堂设置了职业道德、职业形象、自我提升、办公技能、职业规划等方面课程。课堂萃取了职业发展所需的通用型关键性知识与技能，帮助职业人士提升职业能力，获得更好的职业发展。',
        },
        {
          logo: require('@/assets/home/college6.png'),
          organizationName: '乡村振兴书院',
          organizationId: 6,
          des:
            '乡村振兴书院以服务国家乡村振兴战略为目标，以乡村人才振兴为重点，以培训、咨询等智力服务为手段，为乡村振兴提供教育支撑。通过农业产业技术、工艺、经营、管理、设计五类人才的培训，保障农业产业从创业经营、产品经营到资本经营的递次成长；通过提升农民农业技术、职业技能水平，提升农民的收入能力，实现生活富裕；宣传法制党建，建设文明乡风；提升农村工作者理论与实践能力，实现有效治理。',
        },
        {
          logo: require('@/assets/home/college8.png'),
          organizationName: '资本大讲堂',
          organizationId: 8,
          des:
            '资本大讲堂是企业高层与资本从业人士的线上学习交流平台，着力于提升企业管理者对于资本手段的运用。通过投融资、IPO、并购等课程的设置，帮助企业管理者了解资本对于企业的作用，掌握资本对于企业的价值，帮助企业加速发展，实现企业从n-∞的产业腾飞。',
        },
      ],
      token: getCookie('myToken'),
    }
  },
  mounted() {
    // this.getOrgRecmmond()
  },
  methods: {
    // async getOrgRecmmond() {
    //   const [res, err] = await to(orgRecmmond({ page: 1, size: 12 }))
    //   if (err) return
    //   this.orgRecmmond = res.data.list
    // },
    async toUse() {
      if (!this.token) {
        setCookie('Inputphone', this.phone)
        setCookie('redirectUrl', window.location.href)
        window.location.href = process.env.VUE_APP_LOGIN_CENTER
      } else {
        const [res, err] = await to(addorg({ organizationName: this.name }))
        if (err) return this.$message.warning(err.msg)
        this.$router.push({
          path: '/createSuc',
          query: { name: res.data.organizationName },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  .section .container {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
  }
  .section01 {
    width: 100%;
    height: 550px;
    background: linear-gradient(90deg, #15b894 50%, #5ec2ab 50%);
    .bg {
      width: 100%;
      height: 100%;
      background: url('../../assets/home/s1_bg.png') no-repeat center;
    }
    .content {
      display: flex;
      padding-top: 50px;
      justify-content: space-between;
      .text {
        width: 386px;
        color: #ffffff;
        padding-top: 23px;
        padding-left: 72px;
        h3 {
          font-size: 48px;
          font-weight: bold;
          line-height: 67px;
          margin-bottom: 9px;
        }
        .slogan {
          position: relative;
          font-size: 24px;
          line-height: 33px;
          margin-bottom: 51px;
          &::before {
            position: absolute;
            left: -1px;
            bottom: -5px;
            content: '';
            width: 338px;
            height: 2px;
            background: #ffffff;
          }
        }
        h4 {
          font-size: 30px;
          line-height: 42px;
          font-weight: 500;
          margin-bottom: 9px;
        }
        p {
          font-size: 20px;
          line-height: 30px;
        }
        .form {
          margin-top: 24px;
          p {
            font-size: 20px;
            color: #ffffff;
            margin-bottom: 7px;
          }
          .input {
            width: 386px;
            height: 55px;
            background: #ffffff;
            border-radius: 14px;
            display: flex;
            align-items: center;
            input {
              box-sizing: border-box;
              // border: 0;
              width: 251px;
              height: 100%;
              border-radius: 10px 0 0 10px;
              border: 0;
              outline: none;
              font-size: 20px;
              padding: 0 14px;
              &::placeholder {
                font-size: 21px;
                color: #b2b2b2;
              }
            }
            .btn {
              box-sizing: border-box;
              cursor: pointer;
              width: 135px;
              height: 55px;
              line-height: 55px;
              background: #f7c941;
              border-radius: 10px;
              font-size: 24px;
              font-weight: 400;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
      img {
        width: 479px;
        height: 395px;
        margin-right: 84px;
      }
    }
  }
  .section02 {
    padding-top: 46px;
    padding-bottom: 51px;
    .title {
      width: 423px;
      line-height: 42px;
      position: relative;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #15b894;
      margin: 0 auto;
      margin-bottom: 46px;
      &::before {
        position: absolute;
        left: -135px;
        top: 22px;
        content: '';
        width: 244px;
        height: 1px;
        background: #15b894;
      }
      &::after {
        position: absolute;
        right: -135px;
        top: 22px;
        content: '';
        width: 244px;
        height: 1px;
        background: #15b894;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 180px;
        height: 180px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        padding-top: 16px;
        text-align: center;
        margin: 0 20px;
        img {
          width: 76px;
          height: 76px;
          margin-bottom: 18px;
        }
        p {
          line-height: 29px;
          font-size: 21px;
          color: #15b894;
          margin-bottom: 18px;
        }
        .point {
          position: relative;
          width: 8px;
          height: 8px;
          background: #15b894;
          border-radius: 50%;
          &::before {
            content: '';
            position: absolute;
            left: -9px;
            top: 2px;
            width: 5px;
            height: 5px;
            background: #15b894;
            border-radius: 50%;
          }
          &::after {
            content: '';
            position: absolute;
            right: -9px;
            top: 2px;
            width: 5px;
            height: 5px;
            background: #15b894;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .section03 {
    .block {
      display: flex;
      justify-content: center;
      margin-bottom: 87px;
      img {
        width: 380px;
        height: 326px;
      }
      .text {
        width: 703px;
        padding-left: 117px;
        padding-top: 32px;
        .name {
          font-size: 24px;
          font-weight: 700;
          color: #15b894;
          line-height: 33px;
          margin-bottom: 12px;
        }
        .intro {
          font-size: 18px;
          color: #343434;
          line-height: 36px;
          margin-bottom: 19px;
          p {
            text-indent: 2em;
          }
        }
        .btn {
          width: 200px;
          height: 44px;
          cursor: pointer;
          background: #15b894;
          border-radius: 6px;
          font-size: 20px;
          text-align: center;
          color: #ffffff;
          line-height: 44px;
        }
      }
      &:nth-of-type(2) {
        margin-bottom: 72px;
        .text {
          width: 711px;
          padding: 0;
          padding-top: 50px;
          padding-right: 109px;
        }
        img {
          width: 380px;
          height: 387px;
        }
      }
      &:nth-of-type(3) {
        margin-bottom: 40px;
        .text {
          padding: 0;
          width: 1200px;
          margin-bottom: 30px;
          .intro {
            text-indent: 2em;
          }
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          li {
            box-sizing: border-box;
            margin-right: 81px;
            width: 320px;
            height: 178px;
            background: #ffffff;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 27px;
            &:last-child {
              margin-right: 0;
            }
            .img {
              position: relative;
              width: 84px;
              height: 72px;
              background: url('../../assets/home/s3_card_icon_bg.png') no-repeat center;
              margin-bottom: 20px;
              .icon {
                position: absolute;
                right: -8px;
                top: -4px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 53px;
                height: 53px;
                background: #15b894;
              }
            }
            &:nth-of-type(1) {
              .icon {
                img {
                  width: 29px;
                  height: 29px;
                }
              }
            }
            &:nth-of-type(2) {
              .icon {
                img {
                  width: 27px;
                  height: 30px;
                }
              }
            }
            &:nth-of-type(3) {
              .icon {
                img {
                  width: 31px;
                  height: 27px;
                }
              }
            }
            p {
              line-height: 14px;
              text-align: center;
              font-size: 14px;
              color: #343434;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      &:nth-of-type(4) {
        margin-bottom: 74px;
        justify-content: space-between;
        .text {
          width: 711px;
          padding: 0;
          padding-top: 62px;
          padding-right: 89px;
        }
        img {
          width: 380px;
          height: 258px;
        }
      }
    }
  }
  .section04 {
    padding-bottom: 60px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        width: 510px;
        .title {
          position: relative;
          font-size: 24px;
          font-weight: 700;
          color: #15b894;
          margin-bottom: 37px;
          &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -10px;
            background: #15b894;
          }
        }
        .info {
          p {
            font-size: 18px;
            color: #343434;
            line-height: 36px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .section05 {
    .title {
      width: 423px;
      line-height: 42px;
      position: relative;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #15b894;
      margin: 0 auto;
      margin-bottom: 28px;
      &::before {
        position: absolute;
        left: -135px;
        top: 22px;
        content: '';
        width: 244px;
        height: 1px;
        background: #15b894;
      }
      &::after {
        position: absolute;
        right: -135px;
        top: 22px;
        content: '';
        width: 244px;
        height: 1px;
        background: #15b894;
      }
    }
    ul {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 44px;
      .college {
        cursor: pointer;
        box-sizing: border-box;
        width: 280px;
        height: 116px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        margin-right: 26px;
        margin-bottom: 23px;
        padding: 22px 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // &:hover {
        //   transition: all 0.3s;
        //   display: block;
        //   padding: 12px 17px;
        //   background: #15b894;
        //   border-radius: 5px;
        //   box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        //   .name {
        //     color: #fff;
        //     line-height: 20px;
        //     font-size: 14px;
        //   }
        //   .des {
        //     overflow: visible;
        //     width: 100%;
        //     color: #fff;

        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     display: -webkit-box;
        //     -webkit-box-orient: vertical;
        //     -webkit-line-clamp: 4;
        //   }
        //   .logo {
        //     display: none;
        //   }
        // }
        &:nth-of-type(4n) {
          margin-right: 0;
        }
        .logo {
          width: 72px;
          height: 72px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 18px;
          font-weight: 400;
          color: #343434;
          line-height: 25px;
          width: 100%;
          @extend %one-line-text;
          margin-bottom: 7px;
        }
        .des {
          width: 135px;
          font-size: 9px;
          color: #9e9e9e;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    height: 351px;
    background: #333333;
    padding-top: 25px;
  }
  .foot {
    width: 100%;
  }
  .footer a {
    color: #949494;
  }
  .foot_m {
    display: flex;
    justify-content: center;
  }
  .item-msd {
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
  }
  .font-Comp {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    img {
      width: 62px;
      height: 62px;
      margin-right: 14px;
    }
    .des {
      font-size: 12px;
    }
  }
  .font-Dec {
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    padding-top: 11px;
  }
  .font-So {
    font-size: 12px;
    color: #ffffff;
    line-height: 17px;
  }
  .items-jh {
    margin-top: 33px;
    display: flex;
  }
  .items-jh img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .item-gzh {
    margin-left: 86px;
    margin-top: 5px;
  }
  .item-gzh img {
    display: block;
    width: 126px;
    height: 126px;
    margin-bottom: 9px;
  }
  .item-ewm {
    margin-left: 14px;
    margin-top: 5px;
  }
  .item-ewm img {
    display: block;
    width: 126px;
    height: 126px;
    margin-bottom: 9px;
  }
  .item-gzh p,
  .item-ewm p {
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #fff;
  }
  .item-qt {
    margin-left: 82px;
  }
  .items-jhs {
    display: flex;
  }
  .items-jhs img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .items-jhp {
    display: flex;
    margin-top: 33px;
  }
  .items-jhp img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .foot-n {
    margin-top: 58px;
    text-align: center;
  }
  .help {
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #949494;
  }
  .help span {
    margin-left: 63px;
  }
  .foot-n a {
    display: block;
    text-decoration: none;
    font-size: 12px;
    margin-top: 5px;
  }
  .Powerby {
    font-size: 12px;
    color: #949494;
    text-align: center;
    margin-top: 5px;
  }
}
</style>
